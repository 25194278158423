//IMPORTS

import Headroom from "headroom.js";
import GLightbox from 'glightbox';
import 'slick-carousel';
import AOS from 'aos';
import 'bootstrap';


// lazysizes
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';



///////////////////////////////////////////
// scrollspy
///////////////////////////////////////////

const options = {
  sectionClass: '.scrollspy',           // Query selector to your sections
  menuActiveTarget: '.nav-link',       // Query selector to your elements that will be added `active` class
  offset: 100,                          // Menu item will active before scroll to a matched section 100px
}




//jquery
$(document).ready(function($){





 ///////////////////////////////////////////
// glightbox
///////////////////////////////////////////
 const lightbox = GLightbox();
 

 ///////////////////////////////////////////
// headroom
///////////////////////////////////////////


 var options = {
  
  // or you can specify offset individually for up/down scroll
  offset: {
      up: 200,
      down: 200
  },
  tolerance : {
    up : 5,
    down : 0
},
 
 
  
};


  var element = document.querySelector("header");
  var headroom  = new Headroom(element,options);
  // initialise
  headroom.init();

///////////////////////////////////////////
// aos
///////////////////////////////////////////

  AOS.init();


///////////////////////////////////////////
// slider
///////////////////////////////////////////


   $('.stage__slider').slick({
    rows : 0,
     dots: true,
     infinite: true,
     speed: 500,
     fade: true,
     cssEase: 'linear',
     arrows : false,
     
   });

   $('.stage__ticker-content').slick({
    dots: false,
    vertical: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    verticalSwiping: true,
    prevArrow: false,
 nextArrow: false,
 autoplay: true,
 autoplaySpeed: 2000,
  });


   $(".prev-btn").click(function () {
		$(".slider__wrapper").slick("slickPrev");
	});

	$(".next-btn").click(function () {
		$(".slider__wrapper").slick("slickNext");
	});
  $(".prev-btn").click(function () {
   $(".testimonials__wrapper").slick("slickPrev");
 });

 $(".next-btn").click(function () {
   $(".testimonials__wrapper").slick("slickNext");
 });
	$(".prev-btn").addClass("slick-disabled");

  $(".slider__wrapper").on("afterChange", function () {
		if ($(".slick-prev").hasClass("slick-disabled")) {
			$(".prev-btn").addClass("slick-disabled");
		} else {
			$(".prev-btn").removeClass("slick-disabled");
		}
		if ($(".slick-next").hasClass("slick-disabled")) {
			$(".next-btn").addClass("slick-disabled");
		} else {
			$(".next-btn").removeClass("slick-disabled");
		}
	});

 



});


document.addEventListener('DOMContentLoaded', function () {

  const faqItems = document.querySelectorAll('.faq__item');

  faqItems.forEach(item => {
      const question = item.querySelector('.question');
      const answer = item.querySelector('.answer');
  
      question.addEventListener('click', () => {
          const isExpanded = question.getAttribute('aria-expanded') === 'true';
  
          // Schließe alle anderen Antworten
          faqItems.forEach(otherItem => {
              const otherQuestion = otherItem.querySelector('.question');
              const otherAnswer = otherItem.querySelector('.answer');
  
              if (otherItem !== item) {
                  otherQuestion.setAttribute('aria-expanded', 'false');
                  otherAnswer.style.maxHeight = '0';
                  otherAnswer.addEventListener('transitionend', () => {
                      if (otherAnswer.style.maxHeight === '0px') {
                          otherAnswer.hidden = true;
                      }
                  }, { once: true });
              }
          });
  
          // Öffne oder schließe die aktuelle Antwort
          question.setAttribute('aria-expanded', !isExpanded);
  
          if (isExpanded) {
              answer.style.maxHeight = answer.scrollHeight + 'px'; // Set initial height for smooth transition
              requestAnimationFrame(() => {
                  answer.style.maxHeight = '0';
              });
              answer.addEventListener('transitionend', () => {
                  if (answer.style.maxHeight === '0px') {
                      answer.hidden = true;
                  }
              }, { once: true });
          } else {
              answer.hidden = false;
              answer.style.maxHeight = answer.scrollHeight + 'px';
          }
      });
  
      question.addEventListener('keydown', (event) => {
          if (event.key === 'Enter' || event.key === ' ') {
              event.preventDefault();
              question.click();
          }
      });
  });
});